import React, { Component } from "react"
import Nav from "../components/nav/nav"
import Seo from "../components/seo/seo"
import GlofoxTrainers from "../components/glofox/trainers/trainers"

class Trainers extends Component {
  render() {
    return (
      <Seo title={'Trainers'}>
          <GlofoxTrainers />
          <Nav showLogo={true} isDarkBackground={true} asFab={true} />
      </Seo>
    )
  }
}

export default Trainers;
